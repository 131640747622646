<template>
  <v-card class="mx-auto elevation-0" width=100% height=100%>
    <v-card class="elevation-0">
      <v-avatar size="120">

        <v-icon size="120" color='#e4405f' style="transform: rotate(0deg)">mdi-webrtc</v-icon>
      </v-avatar>
    </v-card>
    <v-card class="mx-auto">
      <v-card-text class="pt-4">
        <v-form ref="form">
          <v-text-field label="Username " v-model="username" required></v-text-field>
          <v-text-field label="Password (*)" v-model="password" type="password" required></v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="submit" class="green white--text">Sign in</v-btn>
      </v-card-actions>

      <v-card-actions>
        <v-btn @click="register" class="blue white--text">Register</v-btn>
      </v-card-actions>

    </v-card>



    <v-card flat tile width="100%" class="text-center">

    </v-card>

    <v-toolbar absolute bottom flat tile width=100%>
      <v-card flat tile width="100%" class="text-center">
        <v-btn color="primary" href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">
          粤ICP备05065366号
        </v-btn>
      </v-card>
      <v-card flat tile width="100%" class="text-center">
        <v-btn color="primary" href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">
          粤公网安备 44030602006328号
        </v-btn>
      </v-card>
      <v-card flat tile width="100%" class="text-center">
        <strong>&copy;</strong><strong>Copyright O 深圳市兴天下科技有限公司</strong>
      </v-card>
    </v-toolbar>

    <v-dialog max-width="400" v-model="showdialog" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ dialogtitle }}
        </v-card-title>

        <v-spacer></v-spacer>
        <v-card-text>

        </v-card-text>
        <v-card-text>
          {{ dialogmessage }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="hidedialog">
            {{ dialogbttile }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>





</template>

<script>

import { Base64 } from 'js-base64'
export default {
  name: 'login',

  data: () => ({
    xsrf: '',
    username: '',
    password: '',
    dialogtitle: '',
    dialogmessage: '',
    dialogbttile: '',
    showdialog: false,


  }),
  created() {
    // console.log('login=>created');
    var xsrf = this.getCookie("_xsrf")
    if (xsrf != "") {
      var xsrflist = xsrf.split("|");

      // console.log("login xsrflist ->",xsrflist[0])
      this.xsrf = Base64.decode(xsrflist[0])
      //  console.log("login xsrf ->",this.xsrf)
    }

  },
  methods: {
    getCookie: function (cname) {
      var name = cname + "=";
      var ca = document.cookie.split(';');

      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1);
        if (c.indexOf(name) != -1) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    hidedialog() {
      this.showdialog = false;
    },
    submit() {
      console.log("Home oncartclick  username " + this.username);
      console.log("Home oncartclick  password " + this.password);
      if (this.username == '' || this.password == '') {
        this.dialogtitle = "Warning";
        this.dialogmessage = "User name or Password is empty  !";
        this.dialogbttile = "Close";
        this.showdialog = true;
        return;
      }
      var UserInfo = { user: this.username, pwd: this.password };

      this.axios.post('/api/userlogin', UserInfo, {
        headers: {
          'Access-Control-Allow-Origin': "*",
          'X-Xsrftoken': this.xsrf
        }
      })
        .then(res => {
          console.log('res=>', res.data);

          if (res.data.state == 200) {
            this.$store.commit('setUserInfoValue', UserInfo);
            localStorage.setItem('sessionon', res.data.sessionon);
            this.global.setWebSessionon(res.data.sessionon)
            localStorage.setItem('userinfo', JSON.stringify(UserInfo));
            this.$router.push("/")

          } else {

            this.dialogtitle = "Warning";
            this.dialogmessage = res.data.message + "  error  code " + res.data.state;
            this.dialogbttile = "Close";
            this.showdialog = true;
            return;

          }


        })
        .catch(error => {
          console.log('error=>', error);
          this.dialogtitle = "Warning";
          this.dialogmessage = "net work  error " + error;
          this.dialogbttile = "Close";
          this.showdialog = true;
          return;
        })



    },
    register() {
      this.$router.push("register")
    },
    tobaidu() {
      this.push("https://beian.miit.gov.cn/")
    },
  }
}
</script>
<style scoped>
.v-card {
  text-align: center;
  padding: 1em;

}

.v-card__title {
  justify-content: center;
}

.v-btn {
  width: 100%;
}

.link-color {
  color: #d38841;
  text-decoration: none;
}


.v-card__actions {
  display: block;
}
</style>
